import React, {useState} from "react"
import { Formik, Form, Field } from "formik"
import styled from "@emotion/styled"
import scrollTo from 'gatsby-plugin-smoothscroll';

import Container from "./Container"

interface Values {
  hrubaMzdaSmluvni: number
  trinactaMzda: number
  ctrnactaMzda: number
  hrubaHodnotaBenefity: number
  pocetHodinProplacenychPrescasuMesicne: number
  pocetHodinNocnichSmenMesicne: number
  pocetHodinProplacenychVikendovychSmenMesicne: number
  praceVeStizenemProstrediValue: boolean
  pocetHodinPracePresSvatekMesicne: number
  hodnotaStravenky: number
  pocetTeleviznichPrijimacu: number
  pocetRadiovychPrijimacu: number
  pocetZamestnancuVPodniku: number
  placeneVolnoNadRamecZakonaRocneDny: number
}

const zivotniMinimum = 4620;
const minimalniHodinovaMzda = 96.4;
const minimalniMzda = 16200;
const prumernaMzda = 40086;
const prispevekZamestnavatele = 0.55;
const pocetPracovnichDniMinusDovolena = 232;
const slevaNaPoplatnika = 2570;
const rocniFondPracovnihoCasuVHodinach = 2080;
const prumernyPocetPracovnichHodinZaMesic = 168;
const nocniPraceProcentualniPriplatek = 0.1;
const rizikovaPraceAbsolutniHodinovyPriplatek = minimalniHodinovaMzda * 0.1;
const praceOSvatkuProcentniPriplatek = 1;
const praceOVikenduProcentualniPriplatek = .1;
const pracePrescasZakladPlusProcentualniPriplatek = 1.25;
const pocetDniStatnichSvatkuMimoVikend = 8;
const pocetPohrbuRocne = 0.13;
const lekarskaOsetreniRocne = 3.5;
const doprovodKLekari = 3.5;
const dovolenaZakladDny = 20;
const socialniPojisteniZamestnavatel = 24.8;
const socialniPojisteniZamestnanec = 6.50;
const urazovePojisteniZamestnavatel = 1.2;
const urazovePojisteniZamestnanec = 0;
const zdravotniPojisteniZamestnavatel = 9;
const zdravotniPojisteniZamestnanec = 4.5;
const koncesionarskyPoplatekTelevizniPrijimac = 135;
const koncesionarskyRadiovyPrijimac = 45;

const Actualisation = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 2rem;
  font-weight: 700;
  color: var(--yellow);
  background-color: var(--darkGray);

  @media screen and (max-width: 850px) {
    top: unset;
    bottom: 100%;
    padding: 0.3rem 1.5rem;
    font-size: 0.85rem;
  }
`

const StyledForm = styled(Form)`
  display: grid;
  gap: 1rem 3rem;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 825px) {
    grid-template-columns: 1fr;
  }
`

const InputItem = styled.div`
  label {
    margin-bottom: .2rem;
    display: block;
    font-weight: 700;
  }

  input:not([type="checkbox"]) {
    font-size: 16px !important;
    width: 100%;
    border-radius: 0;
    padding: .5rem 1rem;
    border: 1px solid var(--darkGray);
    &:focus-visible {
      outline: 0;
      box-shadow: 0 0 2px 0 var(--yellow);
    }
  }
`

const CheckboxItemFakeInput = styled.div`
  position: relative;
  width: 33px;
  height: 33px;
  border: 1px solid var(--darkGray);
  &::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    bottom: 4px;
    right: 4px;
    display: block;
    background-color: transparent;
    transition: .15s background-color ease-in-out;
  }
`

const CheckboxItem = styled(InputItem)`
  position: relative;

  label {
    margin-bottom: .2rem;
    display: block;
    font-weight: 700;
  }

  input {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 33px;
    height: 33px;
    opacity: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    &:focus-visible + ${CheckboxItemFakeInput } {
      box-shadow: 0 0 4px 0 rgba(249, 216, 61, 1);
    }
    &:checked + ${CheckboxItemFakeInput} {
      &::before {
        background-color: rgba(249, 216, 61, 1);
      }
    }
  }
`

const Block = styled.div`
  margin-bottom: var(--blockVerticalSpacing);
`

const Wrapper = styled.div`
  box-shadow: var(--boxShadow);
`

const CalculatorWrapper = styled.div`
  position: relative;
  padding: var(--blockPadding);
  background-color: var(--white);
`

const ResultsWrapper = styled.div`
  display: grid;
  gap: 1rem;
  padding: var(--blockPadding);
  background-color: var(--darkGray);
  color: var(--white);
  a {
    color: var(--white);
  }
`

const Result = styled.div`
  display: flex;
  gap: 2rem;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  @media screen and (max-width: 850px) {
    display: block;
  }
`

const ResultLabel = styled.div`
  font-size: 1.2em;
`

const ResultOutcome = styled.div`
  color: var(--yellow);
  font-size: var(--h1);
  font-weight: 700;
`

const Button = styled.button`
  margin-top: 1.5rem;
  justify-self: flex-start;
  background-color: var(--darkGray);
  color: var(--yellow);
  padding: .75rem 2rem;
  font-weight: 700;
  font-size: 1.3rem;
  border: 0;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 1rem;
  }
  @media (hover: hover) {
    transition: all .15s ease-in-out;
    &:hover {
      color: var(--darkGray);
      background-color: var(--yellow);
    }
  }
`

const Calculator: React.FC<{}> = () => {
  const initialValues: Values = {
    hrubaMzdaSmluvni: prumernaMzda,
    trinactaMzda: 0,
    ctrnactaMzda: 0,
    hrubaHodnotaBenefity: 0,
    pocetHodinProplacenychPrescasuMesicne: 0,
    pocetHodinNocnichSmenMesicne: 0,
    pocetHodinProplacenychVikendovychSmenMesicne: 0,
    praceVeStizenemProstrediValue: false,
    pocetHodinPracePresSvatekMesicne: 0,
    hodnotaStravenky: 80,
    pocetTeleviznichPrijimacu: 0,
    pocetRadiovychPrijimacu: 1,
    pocetZamestnancuVPodniku: 50,
    placeneVolnoNadRamecZakonaRocneDny: 5
  }
  const [myValues, setMyValues] = useState(initialValues)
  const onSubmit = (values) => {
    console.clear();
    setMyValues(values)
  }

  const nakladyNaKoncesionarskePoplatkyRocneNaZamestnance = (myValues.pocetTeleviznichPrijimacu * koncesionarskyPoplatekTelevizniPrijimac) / myValues.pocetZamestnancuVPodniku + (myValues.pocetRadiovychPrijimacu * koncesionarskyRadiovyPrijimac) / myValues.pocetZamestnancuVPodniku;
  console.log('Náklady na koncesionářské poplatky ročně na zaměstnance: ' + nakladyNaKoncesionarskePoplatkyRocneNaZamestnance);

  const prispevekNaStravovaniRocni = (() => {
    if (myValues.hodnotaStravenky > 0) {
      return myValues.hodnotaStravenky * prispevekZamestnavatele * pocetPracovnichDniMinusDovolena;
    } else return 0;
  })();
  console.log('Příspěvěk na stravování roční: ' + prispevekNaStravovaniRocni);

  const prispevekNaStravovaniDenni = prispevekNaStravovaniRocni / pocetPracovnichDniMinusDovolena;
  console.log('Příspěvek na stravování denní: ' + prispevekNaStravovaniDenni);

  const hodinovaHrubaMzdaSmluvni = Math.round(myValues.hrubaMzdaSmluvni / prumernyPocetPracovnichHodinZaMesic);
  console.log('Hodinová hrubá mzda smluvní: ' + hodinovaHrubaMzdaSmluvni);

  const pocetHodinSPrescasy = prumernyPocetPracovnichHodinZaMesic + myValues.pocetHodinProplacenychPrescasuMesicne;
  console.log('Počet hodin s přesčasy: ' + pocetHodinSPrescasy);

  const praceVeStizenemProstredi = (() => {
    if (myValues.praceVeStizenemProstrediValue === true ) {
      console.log('Práce ve stíženém prostředí: Ano')
      return 1;
    } else
    console.log('Práce ve stíženém prostředí: Ne')
    return 0;
  })();

  const nakladyNocniPrace = (myValues.pocetHodinNocnichSmenMesicne * nocniPraceProcentualniPriplatek * hodinovaHrubaMzdaSmluvni) + (myValues.pocetHodinNocnichSmenMesicne * praceVeStizenemProstredi * rizikovaPraceAbsolutniHodinovyPriplatek);
  console.log('Náklady noční práce: ' + nakladyNocniPrace);

  const nakladyVikendovePrace = (myValues.pocetHodinProplacenychVikendovychSmenMesicne * praceOVikenduProcentualniPriplatek * hodinovaHrubaMzdaSmluvni) + (myValues.pocetHodinProplacenychVikendovychSmenMesicne * praceVeStizenemProstredi * rizikovaPraceAbsolutniHodinovyPriplatek);
  console.log('Náklady víkendové práce: ' + nakladyVikendovePrace);

  const nakladyPrescasuNejenPriplatek = (myValues.pocetHodinProplacenychPrescasuMesicne * pracePrescasZakladPlusProcentualniPriplatek * hodinovaHrubaMzdaSmluvni) + (praceVeStizenemProstredi * rizikovaPraceAbsolutniHodinovyPriplatek * myValues.pocetHodinProplacenychPrescasuMesicne);
  console.log('Náklady přesčasů - nejen příplatek: ' + nakladyPrescasuNejenPriplatek);

  const nakladyPracePresSvatek = (myValues.pocetHodinPracePresSvatekMesicne * praceOSvatkuProcentniPriplatek * hodinovaHrubaMzdaSmluvni) + (myValues.pocetHodinPracePresSvatekMesicne * praceVeStizenemProstredi * rizikovaPraceAbsolutniHodinovyPriplatek);
  console.log('Náklady práce přes svátek: ' + nakladyPracePresSvatek);

  const priplatkyMimoSvatky = nakladyNocniPrace + nakladyVikendovePrace + nakladyPrescasuNejenPriplatek;
  console.log('Příplatky mimo svátky: ' + priplatkyMimoSvatky);

  const spoluPriplatky = priplatkyMimoSvatky + nakladyPracePresSvatek;
  console.log('Příplatky celkem: ' + spoluPriplatky)

  const odhadPrumerneMzdy = myValues.hrubaMzdaSmluvni + nakladyNocniPrace + nakladyVikendovePrace;
  console.log('Odhad průměrné mzdy: ' + odhadPrumerneMzdy);

  const odhadPrumerneMzdySPrescasy = odhadPrumerneMzdy + nakladyPrescasuNejenPriplatek;
  console.log('Odhad průměrné mzdy s přečasy: ' + odhadPrumerneMzdySPrescasy);

  const spoluHrubaMzdaSPriplatky = myValues.hrubaMzdaSmluvni + spoluPriplatky;
  console.log('Hrubá mzda plus příplatky: ' + spoluHrubaMzdaSPriplatky);

  const vyplacenaHrubaMzdaRocnePlus13A14 = (spoluHrubaMzdaSPriplatky * 12) + myValues.trinactaMzda + myValues.ctrnactaMzda;
  console.log('Vyplacená hrubá mzda ročně (plus 13. a 14. plat): ' + vyplacenaHrubaMzdaRocnePlus13A14);

  const mesicniHrubaMzdaPlus13A14 = vyplacenaHrubaMzdaRocnePlus13A14 / 12;
  console.log('Měsíční hrubá mzda s 13. a 14. platem: ' + mesicniHrubaMzdaPlus13A14);

  const maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni = 48 * prumernaMzda;
  console.log('Reálná mzda: Maximální vyměřovací základ ze sociálního pojištění: ' + maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni);

  const maximalniVymerovaciZakladRealnaMzdaZUrazovehoPojisteni = 48 * prumernaMzda;
  console.log('Maximální vyměřovací základ z úrazového pojištění: ' + maximalniVymerovaciZakladRealnaMzdaZUrazovehoPojisteni);

  const vyskaOdvoduRealnaMzdaSocialniPojisteniZamestnavatel = (() => {
    if (spoluHrubaMzdaSPriplatky > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnavatel * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return spoluHrubaMzdaSPriplatky * (socialniPojisteniZamestnavatel / 100);
    }
  })();
  console.log('Reálná mzda: Výška odvodů sociální pojištění zaměstnavatel: ' + vyskaOdvoduRealnaMzdaSocialniPojisteniZamestnavatel);

  const vyskaOdvoduRealnaMzdaSocialniPojisteniZamestnanec = (() => {
    if (myValues.hrubaMzdaSmluvni > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return spoluHrubaMzdaSPriplatky * (socialniPojisteniZamestnanec / 100);
    }
  })();
  console.log('Reálná mzda: Výška odvodů sociální pojištění zaměstnanec: ' + vyskaOdvoduRealnaMzdaSocialniPojisteniZamestnanec);

  const vyskaOdvoduRealnaMzdaUrazovePojisteniZamestnavatel = spoluHrubaMzdaSPriplatky * (urazovePojisteniZamestnavatel / 100);
  console.log('Reálná mzda: Výška odvodů úrazové pojištění zaměstnavatel: ' + vyskaOdvoduRealnaMzdaUrazovePojisteniZamestnavatel);

  const vyskaOdvoduRealnaMzdaZdravotniPojisteniZamestnavatel = spoluHrubaMzdaSPriplatky * (zdravotniPojisteniZamestnavatel / 100);
  console.log('Reálná mzda: Výška odvodů zdravotní pojištění zaměstnavatel: ' + vyskaOdvoduRealnaMzdaZdravotniPojisteniZamestnavatel);

  const odvodySpoluRealnaMzdaZamestnavatelProcent = socialniPojisteniZamestnavatel + urazovePojisteniZamestnavatel + zdravotniPojisteniZamestnavatel;
  console.log('Reálná mzda: Výška odvodů zdravotní pojištění zaměstnavatel v procentech: ' + odvodySpoluRealnaMzdaZamestnavatelProcent +' %');

  const odvodySpoluRealnaMzdaZamestnanecProcent = socialniPojisteniZamestnanec + urazovePojisteniZamestnanec + zdravotniPojisteniZamestnanec;
  console.log('Reálná mzda: Výška odvodů zdravotní pojištění zaměstnanec v procentech: ' + odvodySpoluRealnaMzdaZamestnanecProcent + ' %');

  const odvodySpoluRealnaMzdaZamestnavatelAbsolutne = vyskaOdvoduRealnaMzdaSocialniPojisteniZamestnavatel + vyskaOdvoduRealnaMzdaUrazovePojisteniZamestnavatel + vyskaOdvoduRealnaMzdaZdravotniPojisteniZamestnavatel
  console.log('Reálná mzda: Odvody celkem zaměstanavatel absolutně: ' + odvodySpoluRealnaMzdaZamestnavatelAbsolutne);

  const vyskaOdvoduRealnaMzdaUrazovePojisteniZamestnanec = (() => {
    if (myValues.hrubaMzdaSmluvni > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return urazovePojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return spoluHrubaMzdaSPriplatky * (urazovePojisteniZamestnanec / 100);
    }
  })();

  console.log('Reálná mzda: Výška odvodů úrazové pojištění zaměstnanec: ' + vyskaOdvoduRealnaMzdaUrazovePojisteniZamestnanec);

  const vyskaOdvoduRealnaMzdaZdravotniPojisteniZamestnanec = spoluHrubaMzdaSPriplatky * (zdravotniPojisteniZamestnanec / 100);
  console.log('Reálná mzda: Výška odvodů zdravotné pojištění zaměstnanec: ' + vyskaOdvoduRealnaMzdaZdravotniPojisteniZamestnanec);

  const odvodySpoluRealnaMzdaZamestnanecAbsolutne = vyskaOdvoduRealnaMzdaSocialniPojisteniZamestnanec + vyskaOdvoduRealnaMzdaUrazovePojisteniZamestnanec + vyskaOdvoduRealnaMzdaZdravotniPojisteniZamestnanec
  console.log('Reálná mzda: Odvody celkem zaměstananec absolutně: ' + odvodySpoluRealnaMzdaZamestnanecAbsolutne);

  const maximalniVymerovaciSmluvniMzdaZakladZeSocialnihoPojisteni = 7 * prumernaMzda;
  console.log('Smluvní mzda: Maximální vyměřovací základ ze sociálního pojištění: ' + maximalniVymerovaciSmluvniMzdaZakladZeSocialnihoPojisteni);

  const vyskaOdvoduSmluvniMzdaSocialniPojisteniZamestnavatel = (() => {
    if (myValues.hrubaMzdaSmluvni > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnavatel * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.hrubaMzdaSmluvni * (socialniPojisteniZamestnavatel / 100);
    }
  })();
  console.log('Smluvní mzda: Výška odvodů sociální pojištění zaměstnavatel: ' + vyskaOdvoduSmluvniMzdaSocialniPojisteniZamestnavatel);

  const vyskaOdvoduSmluvniMzdaSocialniPojisteniZamestnanec = (() => {
    if (myValues.hrubaMzdaSmluvni > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.hrubaMzdaSmluvni * (socialniPojisteniZamestnanec / 100);
    }
  })();
  console.log('Smluvní mzda: Výška odvodů sociální pojištění zaměstnanec: ' + vyskaOdvoduSmluvniMzdaSocialniPojisteniZamestnanec);

  const vyskaOdvoduSmluvniMzdaUrazovePojisteniZamestnavatel = myValues.hrubaMzdaSmluvni * (urazovePojisteniZamestnavatel / 100);
  console.log('Smluvní mzda: Výška odvodů úrazové pojištění zaměstnavatel: ' + vyskaOdvoduSmluvniMzdaUrazovePojisteniZamestnavatel);

  const vyskaOdvoduSmluvniMzdaZdravotniPojisteniZamestnavatel = myValues.hrubaMzdaSmluvni * (zdravotniPojisteniZamestnavatel / 100);
  console.log('Smluvní mzda: Výška odvodů zdravotní pojištění zaměstnavatel: ' + vyskaOdvoduSmluvniMzdaZdravotniPojisteniZamestnavatel);

  const odvodySpoluSmluvniMzdaZamestnavatelProcent = socialniPojisteniZamestnavatel + urazovePojisteniZamestnavatel + zdravotniPojisteniZamestnavatel;
  console.log('Smluvní mzda: Odvody celkem zaměstnavatel v procentech: ' + odvodySpoluSmluvniMzdaZamestnavatelProcent + ' %');

  const odvodySpoluSmluvniMzdaZamestnanecProcent = socialniPojisteniZamestnanec + urazovePojisteniZamestnanec + zdravotniPojisteniZamestnanec;
  console.log('Smluvní mzda: Odvody celkem zaměstnanec v procentech: ' + odvodySpoluSmluvniMzdaZamestnanecProcent + ' %');

  const odvodySpoluSmluvniMzdaZamestnavatelAbsolutne = vyskaOdvoduSmluvniMzdaSocialniPojisteniZamestnavatel + vyskaOdvoduSmluvniMzdaUrazovePojisteniZamestnavatel + vyskaOdvoduSmluvniMzdaZdravotniPojisteniZamestnavatel
  console.log('Smluvní mzda: Odvody celkem zaměstnavatel absolutně: ' + odvodySpoluSmluvniMzdaZamestnavatelAbsolutne);

  const vyskaOdvoduSmluvniMzdaUrazovePojisteniZamestnanec = (() => {
    if (myValues.hrubaMzdaSmluvni > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return urazovePojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.hrubaMzdaSmluvni * (urazovePojisteniZamestnanec / 100);
    }
  })();
  console.log('Smluvní mzda: Výška odvodů úrazové pojištění zaměstnanec: ' + vyskaOdvoduSmluvniMzdaUrazovePojisteniZamestnanec);

  const vyskaOdvoduSmluvniMzdaZdravotniPojisteniZamestnanec = myValues.hrubaMzdaSmluvni * (zdravotniPojisteniZamestnanec / 100);
  console.log('Smluvní mzda: Výška odvodů zdravotní pojištění zaměstnanec: ' + vyskaOdvoduSmluvniMzdaZdravotniPojisteniZamestnanec);

  const odvodySpoluSmluvniMzdaZamestnanecAbsolutne = vyskaOdvoduSmluvniMzdaSocialniPojisteniZamestnanec + vyskaOdvoduSmluvniMzdaUrazovePojisteniZamestnanec + vyskaOdvoduSmluvniMzdaZdravotniPojisteniZamestnanec
  console.log('Smluvní mzda: Odvody celkem zaměstnanec absolutně: ' + odvodySpoluSmluvniMzdaZamestnanecAbsolutne);

  const maximalniVymerovaciTrinactaMzdaZakladZeSocialnihoPojisteni = 7 * prumernaMzda;
  console.log('Třináctá mzda: maximální vyměřovací základ ze sociálního pojištění: ' + maximalniVymerovaciTrinactaMzdaZakladZeSocialnihoPojisteni);

  const vyskaOdvoduTrinactaMzdaSocialniPojisteniZamestnavatel = (() => {
    if (myValues.trinactaMzda > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnavatel * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.trinactaMzda * (socialniPojisteniZamestnavatel / 100);
    }
  })();
  console.log('Třináctá mzda: Výška odvodů ze sociálního pojištění zaměstnavatel: ' + vyskaOdvoduTrinactaMzdaSocialniPojisteniZamestnavatel);

  const vyskaOdvoduTrinactaMzdaSocialniPojisteniZamestnanec = (() => {
    if (myValues.trinactaMzda > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.trinactaMzda * (socialniPojisteniZamestnanec / 100);
    }
  })();
  console.log('Třináctá mzda: Výška odvodů ze sociálního pojištění zaměstnanec: ' + vyskaOdvoduTrinactaMzdaSocialniPojisteniZamestnanec);

  const vyskaOdvoduTrinactaMzdaUrazovePojisteniZamestnavatel = myValues.trinactaMzda * (urazovePojisteniZamestnavatel / 100);
  console.log('Třináctá mzda: Výška odvodů z úrazového pojištění zaměstnavatel: ' + vyskaOdvoduTrinactaMzdaUrazovePojisteniZamestnavatel);

  const vyskaOdvoduTrinactaMzdaZdravotniPojisteniZamestnavatel = myValues.trinactaMzda * (zdravotniPojisteniZamestnavatel / 100);
  console.log('Třináctá mzda: Výška odvodů ze zdravotního pojištění zaměstnavatel: ' + vyskaOdvoduTrinactaMzdaZdravotniPojisteniZamestnavatel);

  const odvodySpoluTrinactaMzdaZamestnavatelProcent = socialniPojisteniZamestnavatel + urazovePojisteniZamestnavatel + zdravotniPojisteniZamestnavatel;
  console.log('Třináctá mzda: Odvody celkem zaměstnavatel v procentech: ' + odvodySpoluTrinactaMzdaZamestnavatelProcent + ' %');

  const odvodySpoluTrinactaMzdaZamestnanecProcent = socialniPojisteniZamestnanec + urazovePojisteniZamestnanec + zdravotniPojisteniZamestnanec;
  console.log('Třináctá mzda: Odvody celkem zaměstnanec v procentech: ' + odvodySpoluTrinactaMzdaZamestnanecProcent + ' %');

  const odvodySpoluTrinactaMzdaZamestnavatelAbsolutne = vyskaOdvoduTrinactaMzdaSocialniPojisteniZamestnavatel + vyskaOdvoduTrinactaMzdaUrazovePojisteniZamestnavatel + vyskaOdvoduTrinactaMzdaZdravotniPojisteniZamestnavatel
  console.log('Třináctá mzda: Odvody celkem zaměstnavatel absolutně: ' + odvodySpoluTrinactaMzdaZamestnavatelAbsolutne);

  const vyskaOdvoduTrinactaMzdaUrazovePojisteniZamestnanec = (() => {
    if (myValues.trinactaMzda > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return urazovePojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.trinactaMzda * (urazovePojisteniZamestnanec / 100);
    }
  })();
  console.log('Třináctá mzda: Výška odvodů z úrazového pojištění zaměstnanec: ' + vyskaOdvoduTrinactaMzdaUrazovePojisteniZamestnanec);

  const vyskaOdvoduTrinactaMzdaZdravotniPojisteniZamestnanec = myValues.trinactaMzda * (zdravotniPojisteniZamestnanec / 100);
  console.log('Třináctá mzda: Výška odvodů ze zdravotního pojištění zaměstnavatel: ' + vyskaOdvoduTrinactaMzdaZdravotniPojisteniZamestnanec);

  const odvodySpoluTrinactaMzdaZamestnanecAbsolutne = vyskaOdvoduTrinactaMzdaSocialniPojisteniZamestnanec + vyskaOdvoduTrinactaMzdaUrazovePojisteniZamestnanec + vyskaOdvoduTrinactaMzdaZdravotniPojisteniZamestnanec
  console.log('Třináctá mzda: Odvody celkem zaměstnanec absolutně: ' + odvodySpoluTrinactaMzdaZamestnanecAbsolutne);

  const maximalniVymerovaciCtrnactaMzdaZakladZeSocialnihoPojisteni = 7 * prumernaMzda;
  console.log('Čtrnáctá mzda: maximální vyměřovací základ ze sociálního pojištění: ' + maximalniVymerovaciCtrnactaMzdaZakladZeSocialnihoPojisteni);

  const vyskaOdvoduCtrnactaMzdaSocialniPojisteniZamestnavatel = (() => {
    if (myValues.ctrnactaMzda > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnavatel * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.ctrnactaMzda * (socialniPojisteniZamestnavatel / 100);
    }
  })();
  console.log('Čtrnáctá mzda: Výška odvodů ze sociálního pojištění zaměstnavatel: ' + vyskaOdvoduCtrnactaMzdaSocialniPojisteniZamestnavatel);

  const vyskaOdvoduCtrnactaMzdaSocialniPojisteniZamestnanec = (() => {
    if (myValues.ctrnactaMzda > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return socialniPojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.ctrnactaMzda * (socialniPojisteniZamestnanec / 100);
    }
  })();
  console.log('Čtrnáctá mzda: Výška odvodů ze sociálního pojištění zaměstnanec: ' + vyskaOdvoduCtrnactaMzdaSocialniPojisteniZamestnanec);

  const vyskaOdvoduCtrnactaMzdaUrazovePojisteniZamestnavatel = myValues.ctrnactaMzda * (urazovePojisteniZamestnavatel / 100);
  console.log('Čtrnáctá mzda: Výška odvodů z úrazového pojištění zaměstnavatel: ' + vyskaOdvoduCtrnactaMzdaUrazovePojisteniZamestnavatel);

  const vyskaOdvoduCtrnactaMzdaZdravotniPojisteniZamestnavatel = myValues.ctrnactaMzda * (zdravotniPojisteniZamestnavatel / 100);
  console.log('Čtrnáctá mzda: Výška odvodů ze zdravotního pojištění zaměstnavatel: ' + vyskaOdvoduCtrnactaMzdaZdravotniPojisteniZamestnavatel);

  const odvodySpoluCtrnactaMzdaZamestnavatelProcent = socialniPojisteniZamestnavatel + urazovePojisteniZamestnavatel + zdravotniPojisteniZamestnavatel;
  console.log('Čtrnáctá mzda: Odvody celkem zaměstnavatel v procentech: ' + odvodySpoluCtrnactaMzdaZamestnavatelProcent + ' %');

  const odvodySpoluCtrnactaMzdaZamestnanecProcent = socialniPojisteniZamestnanec + urazovePojisteniZamestnanec + zdravotniPojisteniZamestnanec;
  console.log('Čtrnáctá mzda: Odvody celkem zaměstnanec v procentech: ' + odvodySpoluCtrnactaMzdaZamestnanecProcent + ' %');

  const odvodySpoluCtrnactaMzdaZamestnavatelAbsolutne = vyskaOdvoduCtrnactaMzdaSocialniPojisteniZamestnavatel + vyskaOdvoduCtrnactaMzdaUrazovePojisteniZamestnavatel + vyskaOdvoduCtrnactaMzdaZdravotniPojisteniZamestnavatel
  console.log('Čtrnáctá mzda: Odvody celkem zaměstnavatel absolutně: ' + odvodySpoluCtrnactaMzdaZamestnavatelAbsolutne);

  const vyskaOdvoduCtrnactaMzdaUrazovePojisteniZamestnanec = (() => {
    if (myValues.ctrnactaMzda > maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni) {
      return urazovePojisteniZamestnanec / 100 * maximalniVymerovaciZakladRealnaMzdaZeSocialnihoPojisteni;
    } else {
      return myValues.ctrnactaMzda * (urazovePojisteniZamestnanec / 100);
    }
  })();
  console.log('Čtrnáctá mzda: Výška odvodů z úrazového pojištění zaměstnanec: ' + vyskaOdvoduCtrnactaMzdaUrazovePojisteniZamestnanec);

  const vyskaOdvoduCtrnactaMzdaZdravotniPojisteniZamestnanec = myValues.ctrnactaMzda * (zdravotniPojisteniZamestnanec / 100);
  console.log('Čtrnáctá mzda: Výška odvodů ze zdravotního pojištění zaměstnanec: ' + vyskaOdvoduCtrnactaMzdaZdravotniPojisteniZamestnanec);

  const odvodySpoluCtrnactaMzdaZamestnanecAbsolutne = vyskaOdvoduCtrnactaMzdaSocialniPojisteniZamestnanec + vyskaOdvoduCtrnactaMzdaUrazovePojisteniZamestnanec + vyskaOdvoduCtrnactaMzdaZdravotniPojisteniZamestnanec
  console.log('Čtrnáctá mzda: Odvody celkem zaměstnanec absolutně: ' + odvodySpoluCtrnactaMzdaZamestnanecAbsolutne);

  const rocnispoluHrubaMzdaSPriplatky = spoluHrubaMzdaSPriplatky * 12;
  console.log('Roční hrubá mzda z příplatky: ' + rocnispoluHrubaMzdaSPriplatky);

  const trinactaACtrnactaHrubaMzda = myValues.trinactaMzda + myValues.ctrnactaMzda;
  console.log('Třinácta a čtrnáctá hrubá mzda: ' + trinactaACtrnactaHrubaMzda);

  const odvodyZamestnanceRocne = odvodySpoluRealnaMzdaZamestnanecAbsolutne * 12 + odvodySpoluTrinactaMzdaZamestnanecAbsolutne + odvodySpoluCtrnactaMzdaZamestnanecAbsolutne;
  console.log('Odvody zaměstnance ročně: ' + odvodyZamestnanceRocne);

  const celkovaRocniHrubaMzda = rocnispoluHrubaMzdaSPriplatky + trinactaACtrnactaHrubaMzda - odvodyZamestnanceRocne;
  console.log('Celková roční hrubá mzda: ' + celkovaRocniHrubaMzda);

  const danFO = (() => {
    if (myValues.hrubaMzdaSmluvni < 141700) {
      return rocnispoluHrubaMzdaSPriplatky * 0.15;
    } else {
      return ((rocnispoluHrubaMzdaSPriplatky - 141700 * 12) * 0.19) + (141700 * 0.15);
    }
  })();
  console.log('Daň FO: ' + danFO);

  const zaObedy = (myValues.hodnotaStravenky - prispevekNaStravovaniDenni) * pocetPracovnichDniMinusDovolena;
  console.log('Za obědy: ' + zaObedy);

  const cistaMzdaRocne = rocnispoluHrubaMzdaSPriplatky + trinactaACtrnactaHrubaMzda - odvodyZamestnanceRocne - danFO - zaObedy + slevaNaPoplatnika * 12;
  console.log('Čistá mzda ročně: ' + cistaMzdaRocne);

  const cistaMzdaMesicne = cistaMzdaRocne / 12;
  console.log('Čistá mzda měsíčně: ' + Math.round(cistaMzdaMesicne));

  const dohodnutyCasSPrescasy = pocetHodinSPrescasy * 12;
  console.log('Dohodnutý čas přesčasy (hodin): ' + dohodnutyCasSPrescasy);

  const svatky = pocetDniStatnichSvatkuMimoVikend * 8;
  console.log('Svátky (hodin): ' + svatky);

  const dovolena = 8 * (dovolenaZakladDny + myValues.placeneVolnoNadRamecZakonaRocneDny);
  console.log('Dovolená (hodin): ' + dovolena);

  const osetreni = lekarskaOsetreniRocne * 8;
  console.log('Ošetření (hodin): ' + osetreni);

  const doprovod = doprovodKLekari * 8;
  console.log('Doprovod k lékaři (hodin): ' + doprovod);

  const pohreb = Math.round(pocetPohrbuRocne * 8);
  console.log('Pohřby (hodin): ' + pohreb);

  const celkemHodin = Math.round(dohodnutyCasSPrescasy - svatky - dovolena - doprovod - osetreni - pohreb);
  console.log('Celkem hodin: ' + celkemHodin);

  const cenaPraceRealnaSPrescasy = spoluHrubaMzdaSPriplatky + odvodySpoluRealnaMzdaZamestnavatelAbsolutne;
  console.log('Cena práce reálna s přesčasy: ' + cenaPraceRealnaSPrescasy);

  const mzdovyNakladRocniRealnyVcetneRekrePlusGastroPlusBenefity = cenaPraceRealnaSPrescasy * 12 + prispevekNaStravovaniRocni + myValues.hrubaHodnotaBenefity + nakladyNaKoncesionarskePoplatkyRocneNaZamestnance;
  console.log('Mzdový náklad roční reálný včetně rekreace + gastro + benefity: ' + mzdovyNakladRocniRealnyVcetneRekrePlusGastroPlusBenefity);

  const mzdovyNakladRocniRealnyPlusTrinactyACtrnactyPlat = mzdovyNakladRocniRealnyVcetneRekrePlusGastroPlusBenefity + myValues.trinactaMzda + odvodySpoluTrinactaMzdaZamestnavatelAbsolutne + myValues.ctrnactaMzda + odvodySpoluCtrnactaMzdaZamestnavatelAbsolutne;
  console.log('Mzdový náklad roční reálný plus třináctý a čtrnáctý plat: ' + mzdovyNakladRocniRealnyPlusTrinactyACtrnactyPlat)

  const realnaCenaNaOdpracovanouHodinu = mzdovyNakladRocniRealnyPlusTrinactyACtrnactyPlat / celkemHodin;
  console.log('Reálná cena na odpracovanou hodinu: ' + realnaCenaNaOdpracovanouHodinu);

  const realnaCenaNaOdpracovanyMesic = realnaCenaNaOdpracovanouHodinu * prumernyPocetPracovnichHodinZaMesic;
  console.log('Reálná cena na odpracovaný měsíc: ' + realnaCenaNaOdpracovanyMesic);

  const smluvniPlatNaHodinu = hodinovaHrubaMzdaSmluvni;
  console.log('Smluvní plat na hodinu: ' + smluvniPlatNaHodinu);

  const smluvniPlatMesicni = myValues.hrubaMzdaSmluvni;
  console.log('Smluvní plat měsíční: ' + smluvniPlatMesicni);

  const rozdilMeziCelkovymNaklademASmluvniMzdou = (realnaCenaNaOdpracovanouHodinu / smluvniPlatNaHodinu - 1) * 100;
  console.log('Cena zaměstnance nad rámec smluvního platu: ' + rozdilMeziCelkovymNaklademASmluvniMzdou + ' %');

  const podilCisteMzdyNacelkovychNakladechNaZamestnance = (cistaMzdaMesicne / realnaCenaNaOdpracovanyMesic) * 100;
  console.log('Podíl čisté mzdy na celkových nákladech na zaměstnance: ' + podilCisteMzdyNacelkovychNakladechNaZamestnance + ' %');

  return (
    <Container>
      <Block id="kalkulacka">
        <Wrapper>
          <CalculatorWrapper>
            <h2>Kolik stojí zaměstnanec?</h2>
            <Actualisation>Aktualizováno v říjnu 2022</Actualisation>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              <StyledForm>
                <InputItem>
                  <label htmlFor="hrubaMzdaSmluvni">Hrubá mzda v CZK (Trvalý pracovní poměr)</label>
                  <Field type="number" pattern="[0-9]*" name="hrubaMzdaSmluvni" />
                </InputItem>
                <InputItem>
                  <label htmlFor="trinactaMzda">13. mzda</label>
                  <Field type="number" pattern="[0-9]*" name="trinactaMzda" />
                </InputItem>
                <InputItem>
                  <label htmlFor="ctrnactaMzda">14. mzda</label>
                  <Field type="number" pattern="[0-9]*" name="ctrnactaMzda" />
                </InputItem>
                  <InputItem>
                  <label htmlFor="hrubaHodnotaBenefity">Hrubá hodnota benefitů</label>
                  <Field type="number" pattern="[0-9]*" name="hrubaHodnotaBenefity" />
                </InputItem>
                <InputItem>
                  <label htmlFor="pocetHodinProplacenychPrescasuMesicne">Počet hodin proplacených přesčasů měsíčně</label>
                  <Field type="number" pattern="[0-9]*" name="pocetHodinProplacenychPrescasuMesicne" />
                </InputItem>
                <InputItem>
                  <label htmlFor="pocetHodinNocnichSmenMesicne">Počet hodin nočních směn měsíčně</label>
                  <Field type="number" pattern="[0-9]*" name="pocetHodinNocnichSmenMesicne" />
                </InputItem>
                <InputItem>
                  <label htmlFor="pocetHodinProplacenychVikendovychSmenMesicne">Počet hodin víkendových směn měsíčně</label>
                  <Field type="number" pattern="[0-9]*" name="pocetHodinProplacenychVikendovychSmenMesicne" />
                </InputItem>
                <CheckboxItem>
                  <label htmlFor="praceVeStizenemProstrediValue">Práce ve ztíženém prostředí?</label>
                  <Field type="checkbox" name="praceVeStizenemProstrediValue" />
                  <CheckboxItemFakeInput />
                </CheckboxItem>
                <InputItem>
                  <label htmlFor="pocetHodinPracePresSvatekMesicne">Počet hodin práce přes svátek měsíčně</label>
                  <Field type="number" pattern="[0-9]*" name="pocetHodinPracePresSvatekMesicne" />
                </InputItem>
                <InputItem>
                  <label htmlFor="hodnotaStravenky">Hodnota jedné stravenky</label>
                  <Field type="number" pattern="[0-9]*" name="hodnotaStravenky" />
                </InputItem>
                <InputItem>
                  <label htmlFor="pocetTeleviznichPrijimacu">Počet televizních přijímačů</label>
                  <Field type="number" pattern="[0-9]*" name="pocetTeleviznichPrijimacu" />
                </InputItem>
                <InputItem>
                  <label htmlFor="pocetRadiovychPrijimacu">Počet radiových přijímačů</label>
                  <Field type="number" pattern="[0-9]*" name="pocetRadiovychPrijimacu" />
                </InputItem>
                <InputItem>
                  <label htmlFor="pocetZamestnancuVPodniku">Počet zaměstnanců v podniku</label>
                  <Field type="number" pattern="[0-9]*" name="pocetZamestnancuVPodniku" />
                </InputItem>
                <InputItem>
                  <label htmlFor="placeneVolnoNadRamecZakonaRocneDny">Placené volno nad rámec zákona ročně (dny)</label>
                  <Field type="number" pattern="[0-9]*" name="placeneVolnoNadRamecZakonaRocneDny" />
                </InputItem>

                <Button
                  type="submit"
                  onClick={() => scrollTo('#vysledek')}
                >
                  Vypočítat cenu zaměstnance
                </Button>
              </StyledForm>
            </Formik>
          </CalculatorWrapper>
          <ResultsWrapper id="vysledek">
            <Result>
              <ResultLabel>
                Cena zaměstnance za měsíc / hodinu:
              </ResultLabel>
              <ResultOutcome>
                {Math.round(realnaCenaNaOdpracovanyMesic)} Kč / {Math.round(realnaCenaNaOdpracovanouHodinu)} Kč
              </ResultOutcome>
            </Result>
            <Result>
              <ResultLabel>
                Čistá mzda:
              </ResultLabel>
              <ResultOutcome>
                {Math.round(cistaMzdaMesicne)} Kč
              </ResultOutcome>
            </Result>
            <Result>
              <ResultLabel>
                Podíl čisté mzdy na celkových nákladech na zaměstnance:
              </ResultLabel>
              <ResultOutcome>
                {Math.round(podilCisteMzdyNacelkovychNakladechNaZamestnance)} %
              </ResultOutcome>
            </Result>
            <Result>
              <ResultLabel>
                Cena zaměstnance nad rámec smluvního platu:
              </ResultLabel>
              <ResultOutcome>
                {Math.round(rozdilMeziCelkovymNaklademASmluvniMzdou)} %
              </ResultOutcome>
            </Result>
          </ResultsWrapper>
        </Wrapper>
      </Block>
    </Container>
  )
}

export default Calculator;
